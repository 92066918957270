import { ApolloClient, InMemoryCache, gql } from '@apollo/client';

const client = new ApolloClient({
	uri: window.location.host === 'localhost:8080' ? 'https://dev.pkengage.com/graph' : 'https://dtdb.pkengage.co.uk/graph',
	cache: new InMemoryCache(),
	typeDefs: gql`
        extend type Query {
            token: String
            loggedIn: Boolean!
        }
        extend type Mutation {
            updateToken (
                token: String!
            ): Boolean!
            updateLoggedIn (
                loggedIn: Boolean!
            ): Boolean!
        }
        directive @client on FIELD
        directive @formatDateTime (
            format: String
            timezone: String
            locale: String
        ) on FIELD
        directive @transform (
            handle: String
            transform: String
            height: Int
            width: Int
            mode: String
            position: String
            interlace: String
            quality: Int
            format: String
            immediately: Boolean
        ) on FIELD
        directive @markdown (
            flavor: String
            inlineOnly: Boolean
        ) on FIELD
	`,
});

export default client;
