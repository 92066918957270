import css from './style.scss';
import Check from '../../assets/svg/check.svg';

export default function Thanks ({ name }) {
	return (
		<div class={css.wrap}>
			<div>
				<Check />
				<h2>Thank you for your submission, {name}</h2>
				<p>We’ll get these processed and added to your employee benefits
					file. If we have any questions, a member of the PK Employee
					Benefits team will be in touch.</p>
			</div>
		</div>
	);
}
