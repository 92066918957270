import css from './style.scss';

export default function Deck ({ children }) {
	return (
		<div class={css.wrap}>
			<div class={css.deck}>
				{children}
			</div>
		</div>
	);
}
