import css from './style.scss';
import cls from '../../util/cls';

export default function Tabs ({ tabs, setTab, activeTab }) {
	const onTabClick = tab => () => {
		setTab(tab);
	};

	return (
		<div class={css.wrap}>
			<ul class={css.tabs}>
				{tabs.map(tab => (
					<li key={tab.id}>
						<button
							onClick={onTabClick(tab)}
							class={cls({
								[css.active]: tab.id === activeTab.id,
							})}
						>
							{tab.title}
						</button>
					</li>
				))}
			</ul>
		</div>
	);
}
