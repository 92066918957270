import AuthLayout from '../components/AuthLayout';
import { gql, useMutation } from '@apollo/client';
import SmallThanks from '../components/SmallThanks';
import Label from '../components/Label';
import Button from '../components/Button';

export default function Set () {
	const params = new URLSearchParams(location.search);

	const [setPassword, { data, error, loading }] = useMutation(gql`
		mutation SetPassword (
			$password: String!
			$code: String!
			$id: String!
		) {
			setPassword (
				password: $password
				code: $code
				id: $id
			)
		}
	`, {
		errorPolicy: 'all',
	});

	const onSubmit = async e => {
		e.preventDefault();
		
		const password = e.target.elements.password.value.trim();
		const confirmPassword = e.target.elements.confirmPassword.value.trim();
		
		if (password !== confirmPassword) {
			alert('Your passwords don\'t match!');
			return;
		}
		
		await setPassword({
			variables: {
				password,
				code: params.get('code'),
				id: params.get('id'),
			},
		});
	};

	return (
		<AuthLayout title="Set Password" onSubmit={onSubmit}>
			<h1>Set Your Password</h1>
			<p>Please set and confirm your password below. Please use a unique
				password at least 12 characters long and contains at least 1
				numeric and alphanumeric character.</p>

			{data && data.setPassword ? (
				<SmallThanks message={data.setPassword} />
			) : (
				<>
					{error && <p><strong>{error.message}</strong></p>}

					<Label label="New Password">
						<input
							type="password"
							name="password"
							required
							autoFocus
						/>
					</Label>
					
					<Label label="Confirm Password">
						<input
							type="password"
							name="confirmPassword"
							required
							autoFocus
						/>
					</Label>

					<Button busy={loading}>Set Password</Button>
				</>
			)}
		</AuthLayout>
	);
}
