import css from './style.module.scss';
import Check from '../../assets/svg/check.svg';

export default function SmallThanks ({ message }) {
	return (
		<div className={css.smallThanks}>
			<Check />
			<span>{message}</span>
		</div>
	)
}
