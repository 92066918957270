import { ApolloProvider } from '@apollo/client';
import client from './client';
import Index from './pages';
import Forgot from './pages/forgot';
import Set from './pages/set';

export default function Connect () {
	const path = location.pathname.split('/').pop();

	return (
		<ApolloProvider client={client}>
			{(() => {
				switch (path) {
					case '':
					default:
						return <Index />;
					case 'forgot':
						return <Forgot />;
					case 'set':
						return <Set />
				}
			})()}
		</ApolloProvider>
	);
}
