import AuthLayout from '../components/AuthLayout';
import Label from '../components/Label';
import Button from '../components/Button';
import { gql, useMutation } from '@apollo/client';
import SmallThanks from '../components/SmallThanks';

export default function Forgot () {
	const params = new URLSearchParams(location.search);

	const [requestReset, { data, loading }] = useMutation(gql`
		mutation RequestReset ($email: String!) {
			forgottenPassword (email: $email)
		}
	`);

	const onSubmit = async e => {
		e.preventDefault();

		await requestReset({
			variables: {
				email: e.target.elements.email.value.trim(),
			},
		});
	};

	return (
		<AuthLayout title="Password Reset" onSubmit={onSubmit}>
			<h1>Password Reset</h1>
			<p>If you know you have an account with PK Employee benefits please
				enter your email below and click the request button.</p>

			{data && data.forgottenPassword ? (
				<SmallThanks message={data.forgottenPassword} />
			) : (
				<>
					<Label label="Email Address">
						<input
							type="email"
							name="email"
							required
							defaultValue={params.get('email')}
							autoFocus
						/>
					</Label>

					<Button busy={loading}>Request Password Reset</Button>
				</>
			)}
		</AuthLayout>
	);
}
