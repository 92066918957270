import css from './style.scss';
import Button from '../Button';
import currency from '../../util/currency';
import { useState } from 'preact/hooks';
import { Fragment } from 'preact';

export default function Card ({ tab, card, addToCart, inCart, remainingBudget, holidayMax }) {
	const isCustom = card.type === 'employeeDefinable'
		, isHoliday = card.type === 'holiday';

	const [cost, setCost] = useState(0)
		, [salary, setSalary] = useState(0)
		, [days, setDays] = useState('');

	const onClick = () => {
		const item = {
			...card,
			provider: tab.provider ? tab.provider.title : '',
			cost: card.cost,
		};

		if (isCustom) item.cost = cost;
		else if (isHoliday) {
			item.cost = days * salary / 260;
			item.days = days;
		}

		addToCart(item);
	};

	const holidayCostPerDay = salary / 260;
	const maxDays = holidayMax;
{/* 	const maxDays = Math.min(
		Math.floor(remainingBudget / holidayCostPerDay),
		holidayMax ?? 365,
	); */}
	

	return (
		<>
			<div class={css.card}>
				<header class={css.header}>
					{tab.provider && tab.provider.logo.length > 0 ? (
						<img
							src={tab.provider.logo[0].url}
							srcset={tab.provider.logo[0].srcset}
							alt={tab.provider.title}
						/>
					) : <i />}
					{!(isCustom || isHoliday) && (
						<strong>{currency(card.cost)}</strong>
					)}
				</header>

				<div class={css.text}>
					<strong>{card.title}</strong>
					{!isCustom && (<small>{card.description}</small>)}
				</div>

				{isCustom && (
					<input
						type="number"
						placeholder="Set your amount"
						step={0.01}
						class={css.input}
						min={0}
						onInput={e => setCost(+e.target.value || 0)}
						disabled={inCart}
					/>
				)}

				{isHoliday && (
					<Fragment>
						<label class={css.labelPrefix}>
							<span>£</span>
							<input
								type="number"
								placeholder="Add your salary"
								class={css.input}
								onInput={e => setSalary(+e.target.value || 0)}
								required
								disabled={inCart}
							/>
						</label>
{/* 						{salary > 0 ? (
							<p>Based on your salary you can purchase up to {maxDays} days extra holiday</p>
						) : (
							<p>Please enter your salary to continue</p>
						)} */}
						<input
							type="number"
							placeholder="How many days"
							className={css.input}
							onInput={e => setDays(Math.min(+e.target.value || '', maxDays))}
							min={0}
							max={maxDays}
							value={days}
							required
							disabled={inCart || salary <= 0}
						/>
					</Fragment>
				)}

				<Button onClick={onClick} disabled={(
					inCart
					|| (isCustom && cost === 0)
					|| (isHoliday && days === 0)
				)}>
					Add to selection
				</Button>
			</div>
			{(isCustom || isHoliday) && card.body && (
				<div
					class={css.instructions}
					dangerouslySetInnerHTML={{__html:`<p><strong>How does this work?</strong></p>${card.body}`}}
				/>
			)}
		</>
	);
}
