import { gql, useQuery } from '@apollo/client';
import getSite from '../util/getSite';

export default function useGetGlobals (fragment) {
	const { data } = useQuery(gql`
        query GetDates ($site: String!) {
            globalSet (
                handle: "companySettingsBenefits"
                site: [$site]
            ) {
                ...${fragment.definitions[0].name.value}
            }
        }
        ${fragment}
	`, {
		variables: {
			site: getSite(),
		},
	});

	return data ? data.globalSet : null;
}
