import css from './style.scss';
import Button from '../Button';
import camelToTitle from '../../util/camelToTitle';

export default function DocCard ({ title, cover, document }) {
	if (!document) return null;

	return (
		<div class={css.card}>
			<div class={css.text}>
				<div>
					{title}
					<span>{camelToTitle(cover)}</span>
				</div>
			</div>

			<Button El="a" secondary href={document.url} target="_blank">
				View the documentation
			</Button>
		</div>
	);
}
