import css from './style.scss';
import cls from '../../util/cls';

export default function Button ({
	El = 'button',
	children,
	secondary = false,
	busy = false,
	...props
}) {
	return (
		<El class={cls(css.btn, {
			[css.secondary]: secondary,
			[css.busy]: busy,
		})} {...props}>
			{children}
		</El>
	);
}
