import css from './style.scss';
import cls from '../../util/cls';
import Button from '../Button';
import Label from '../Label';
import useGetGlobals from '../../hooks/useGetGlobals';
import { gql, useMutation } from '@apollo/client';
import currency from '../../util/currency';
import getSite from '../../util/getSite';
import Delete from '../../assets/svg/delete.svg';

const SelectionEmpty = ({ budget }) => (
	<div>
		Your budget
		<strong>{currency(budget)}</strong>
		View the options below and make a selection
	</div>
);

const SelectionCart = ({ cart, removeFromCart, overBudget }) => (
	<>
		<ul class={css.list}>
			{cart.map(item => (
				<li class={css.item} key={item.id}>
					<button
						class={css.delete}
						title="Remove selection"
						onClick={() => removeFromCart(item)}
						type="button"
					>
						<Delete />
					</button>
					<span>{item.title} {item.days > 0 && (<small>({item.days} days)</small>)}</span>
					<em>{currency(item.cost)}</em>
				</li>
			))}
		</ul>
		<footer class={css.footer}>
			{overBudget && (
				<label class={css.accept}>
					<input required type="checkbox" name="acceptsOverage" value="1" />
					I accept that any overspent amount will be deducted from my salary.
				</label>
			)}
			<Button>Submit Selection</Button>
		</footer>
	</>
);

export default function Form ({ cart, removeFromCart, setFinished }) {
	const data = useGetGlobals(gql`
		fragment GetFormGlobals on companySettingsBenefits_GlobalSet {
			budget
		}
	`);

	const [send] = useMutation(gql`
        mutation (
	        $site: String!
	        $firstName: String!
	        $lastName: String!
	        $email: String!
	        $selection: [String]!
	        $acceptsOverage: Boolean
        ) {
            submitSelection (
                site: $site
                firstName: $firstName
                lastName: $lastName
                email: $email
                selection: $selection
	            acceptsOverage: $acceptsOverage
            )
        }
	`);

	if (!data)
		return null;

	const budget = +data.budget;
	const isEmpty = cart.length === 0;
	const cartTotal = cart.reduce((a, b) => {
		a += b.cost;
		return a;
	}, 0);
	const budgetRemaining = currency(budget - cartTotal)
		, overage = currency(cartTotal - budget)
		, overBudget = cartTotal > budget
		, budgetRemainingMonthly = currency((budget - cartTotal) / 12)
		, overageMonthly = currency((cartTotal - budget) / 12);

	const onSubmit = async e => {
		e.preventDefault();

		if (!confirm('I confirm that I understand the information I am submitting'))
			return;

		const { firstName, lastName, email, acceptsOverage } = e.target.elements;

		await send({
			variables: {
				site: getSite(),
				firstName: firstName.value,
				lastName: lastName.value,
				email: email.value,
				selection: cart.map(i => `[${i.provider}] ${i.title} (${currency(i.cost)}${i.days ? `, ${i.days} days` : ''})`),
				acceptsOverage: acceptsOverage ? acceptsOverage.checked : null,
			},
		});

		setFinished(firstName.value);
	};

	return (
		<section class={css.wrap}>
			<form class={css.inner} onSubmit={onSubmit}>
				<div>
					<h2>Your Information</h2>
					<Label label="First Name">
						<input type="text" name="firstName" required />
					</Label>
					<Label label="Last Name">
						<input type="text" name="lastName" required />
					</Label>
					<Label label="Email Address">
						<input type="email" name="email" required />
					</Label>
				</div>
				<div class={css.selectionWrap}>
					<h2>Your Selection</h2>
					<div class={cls(css.selection, {
						[css.empty]: isEmpty,
						[css.cart]: !isEmpty,
					})}>
						{isEmpty ? (
							<SelectionEmpty budget={budget} />
						) : (
							<SelectionCart
								cart={cart}
								removeFromCart={removeFromCart}
								overBudget={overBudget}
							/>
						)}
					</div>
				</div>
				{!isEmpty && (
					<div class={css.totals} key={cartTotal}>
						<strong>Total</strong>
						<span>{currency(cartTotal)}</span>

						<strong>Remaining budget</strong>
						<span class={cls(css.remaining, {
							[css.over]: overBudget,
						})}>{budgetRemaining}</span>

						{overBudget ? (
							<>
								You have gone over your budget. If you wish to
								keep your selections, the overspend
								of {overage} will be deducted from your salary 
								in monthly instalments. Please tick to confirm 
								you are happy with this. If not, please delete 
								your benefits and make new selections.
							</>
						) : (
							<>
								You have an unspent budget of {budgetRemaining}.
								This will be allocated towards an increased
								monthly employer pension contribution
								at {budgetRemainingMonthly} per month.
							</>
						)}
					</div>
				)}
			</form>
		</section>
	);
}
