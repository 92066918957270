import css from './style.scss';
import Wave from '../../assets/svg/wave.svg';
import Logo from '../../assets/svg/logo.svg';
import { gql } from '@apollo/client';
import useGetGlobals from '../../hooks/useGetGlobals';

export default function Header () {
	const data = useGetGlobals(gql`
		fragment GetHeaderGlobals on companySettingsBenefits_GlobalSet {
			logo: companyLogo (limit: 1) @transform (
				width: 200
				height: 62
				immediately: true
                mode: "fit"
			) {
				id
				url
				srcset(sizes: ["1x", "2x"])
			}
            startDate  @formatDateTime (format: "jS F Y")
            endDate @formatDateTime (format: "jS F Y")
            cutoffDate @formatDateTime (format: "jS F Y")
			document (limit: 1) {
				id
				url
				title
			}
		}
	`);

	if (!data)
		return null;

	const { logo: [logo], startDate, endDate, cutoffDate, document: [document] } = data;

	return (
		<header class={css.wrap}>
			<div class={css.inner}>
				<div class={css.header}>
					<img
						class={css.logo}
						src={logo.url}
						srcset={logo.srcset}
						alt=""
					/>
					<div class={css.by}>
						<small>By PK Employee Benefits</small>
						<Logo />
					</div>
				</div>

				<h1>Employee Benefits</h1>
				<p>This calculator allows you to make your benefit selections
					for your coming benefit year which will run
					from {startDate} to {endDate}.</p>
				<p>Should you have any questions about any of the benefits
					available to you please contact PK Employee Benefits
					at <a href="mailto:eb@pkgroup.co.uk">eb@pkgroup.co.uk</a>.</p>
				<p>You must confirm your selections by {cutoffDate}, and all
					benefits will be applicable from {startDate}. Once you have
					made your selection, your benefits will be in place for the
					full year, and you won’t be able to make any changes until
					the next renewal window.</p>
				{document && (
					<p>For additional help completing this process please consult the
						document <a href={document.url} download={document.title} target="_blank">here</a>.</p>
				)}
			</div>
			<Wave class={css.wave} />
		</header>
	);
}
