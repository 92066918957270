import css from './style.scss';
import cls from '../../util/cls';
import Spinner from '../../assets/svg/spinner.svg';

export default function Label ({
	label,
	inline = false,
	spinner = false,
	children,
}) {
	return (
		<label class={cls(css.label, { [css.inline]: inline })}>
			<span>{label}</span>
			{children}
			{spinner && <Spinner />}
		</label>
	);
}
